import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import PageHeader from "../components/PageHeader/PageHeader"
import PageFooter from "../components/PageFooter/PageFooter"
import Seo from "../components/seo"

const NotFoundPage = () => {
  const { sanityNotfoundpage } = useStaticQuery(query)

  return (
    <div style={{ paddingTop: "100vh" }}>
      <Seo title="404" />
      <PageHeader
        titre={"404"}
        sousTitre={"Cette page n'existe pas"}
        media={sanityNotfoundpage.header}
      />
      <PageFooter />
    </div>
  )
}

const query = graphql`
  query {
    sanityNotfoundpage {
      header {
          ...HeaderImage
          ...HeaderYoutubeVideo
      }
    }
  }
`

export const Head = () => <Seo title="404" />

export default NotFoundPage
